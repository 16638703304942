import { createAction, props } from '@ngrx/store';
import { FiltersDto } from '@dto/filters.dto';
import { NotificationListDto } from '@dto/notification-list.dto';
import { WebsocketUpdateDetailDto } from '@dto/websocket-update-detail.dto';
import { NotificationPage } from '@type/notifications.type';

const state = '[Notification List]';

export const initAllNotificationPage = createAction(
  `${state}[Init all Notifications pages]`,
);

export const loadNotificationPage = createAction(
  `${state}[Load Notification Page] Load notifications`,
  props<{
    notificationPage: NotificationPage;
    currentPageNumber: number;
    filters: FiltersDto;
    pageSize: number;
    reload: boolean;
  }>()
);

export const loadNotificationPageSuccess = createAction(
  `${state}[Load Notification Page] Load notifications Success`,
  props<{
    notificationPage: NotificationPage;
    notificationList: NotificationListDto;
    reload: boolean;
  }>()
);

export const loadNotificationPageFailure = createAction(
  `${state}[Load Notification Page] Load notifications Failure`,
  props<{ notificationPage: NotificationPage; error: string }>()
);

export const updateNotificationFromWs = createAction(
  `${state}[Update Notification]`,
  props<{ toUpdate: WebsocketUpdateDetailDto }>()
);
