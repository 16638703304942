import { createSelector } from '@ngrx/store';
import { State } from '@store/state';
import { NotificationPage } from '@type/notifications.type';
import { NotificationListState } from './notification-list.reducer';

const getNotificationListState = (state: State) => state.notificationList;

export const getNotifications = createSelector(
  getNotificationListState,
  (state: NotificationListState) => state.pages
);

export const getNotificationsByPage = (notificationPage: NotificationPage) =>
  createSelector(
    getNotificationListState,
    (state: NotificationListState) => state.pages[notificationPage]
  );

export const getNotificationTotalPages = (notificationPage: NotificationPage) =>
  createSelector(
    getNotificationListState,
    (state: NotificationListState) =>
      state.pages[notificationPage].notificationPagination.totalPages
  );

export const getNotificationTotalNotifications = (
  notificationPage: NotificationPage
) =>
  createSelector(
    getNotificationListState,
    (state: NotificationListState) =>
      state.pages[notificationPage].notificationPagination.totalNotifications
  );

export const getNotificationListByPage = (notificationPage: NotificationPage) =>
  createSelector(
    getNotificationListState,
    (state: NotificationListState) =>
      state.pages[notificationPage].notification.data
  );

export const isNotificationLoadingByPage = (
  notificationPage: NotificationPage
) =>
  createSelector(
    getNotificationListState,
    (state: NotificationListState) =>
      state.pages[notificationPage].notification.isLoading
  );

export const isNotificationErrorByPage = (notificationPage: NotificationPage) =>
  createSelector(
    getNotificationListState,
    (state: NotificationListState) =>
      state.pages[notificationPage].notification.error
  );

export const getNotificationListPageNumberByPage = (
  notificationPage: NotificationPage
) =>
  createSelector(
    getNotificationListState,
    (state: NotificationListState) =>
      state.pages[notificationPage].notificationPagination.currentPageNumber
  );

export const getNotificationListFiltersByPage = (notificationPage: NotificationPage) =>
  createSelector(
    getNotificationListState,
    (state: NotificationListState) =>
      state.pages[notificationPage].notification.filters
  );

export const getPageSizeByPage = (notificationPage: NotificationPage) =>
  createSelector(
    getNotificationListState,
    (state: NotificationListState) =>
      state.pages[notificationPage].notificationPagination.pageSize
  );

export const hasPageBeenReloadedByPage = (notificationPage: NotificationPage) =>
  createSelector(
    getNotificationListState,
    (state: NotificationListState) =>
      state.pages[notificationPage].notification.reload
  );
